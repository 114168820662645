import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const RemoveAsset = ({ asset, handleClose, handleDeleteAsset }) => {
  return (
    <Dialog
      open={asset ? true : false}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" alignItems="center" className="dialog-title">
          <WarningAmberIcon sx={{ marginRight: 1, color: "#FFAB00 !important" }} />
          <b > Remove  </b>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure, You want to delete this asset anymore!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={{ color: "#1E293B !important", textTransform: 'capitalize' }}>
          Cancel
        </Button>
        <Button onClick={handleDeleteAsset} sx={{ color: "#EA6057 !important", textTransform: 'capitalize' }} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveAsset;
