import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";

import useAxios from "../../helpers/Hooks/useAxios";
import { useForm, Controller } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { URLS } from "../../helpers/URLS";
import { toast } from "react-toastify";

const AddEmployeeForm = ({ open, onClose }) => {
  const {
    handleSubmit,
    control,
    register,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  const { fetchData } = useAxios();

  const onSubmit = async (data) => {
    console.log("Form Submitted Data:", data);
    try {
      const resp = await fetchData({
        url: URLS.addEmployee,
        method: "post",
        body: data,
      });
      console.log(resp, 'This is my response')
      if (resp.success) {
        toast.success("Employee Added Successfully");
        reset();
        onClose();
      } else if (resp.status === 400) {
        toast.error(resp.message);
        // setError("email", {
        //   type: "server",
        //   message: resp.message,
        // });
      }
    } catch (error) {
      console.log(error)
      if (error) {
        console.log(error)
        //toast.error(resp.data.message);
      } else {
        toast.error("Email id already present");
        setError("email", {
          type: "server",
          message: "This email is already in use.",
        });
      }
    }

    //onClose(); // Close the form after submission
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ background: "#E0E9F9", color: "#000" }}>
        Add Employee
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {/* Company Name */}
            <Grid item xs={6}>
              <TextField
                label="Company Name"
                fullWidth
                defaultValue={"Motivitylabs Private Limited"}
                {...register("companyName")}
              />
            </Grid>

            {/* Employee Name */}
            <Grid item xs={6}>
              <TextField
                label="Employee Name *"
                fullWidth
                {...register("employeeName", {
                  required: "Employee Name is required",
                })}
                error={!!errors.employeeName}
                helperText={errors.employeeName?.message}
              />
            </Grid>

            {/* Employee Code */}
            <Grid item xs={6}>
              <TextField
                label="Employee Code *"
                fullWidth
                {...register("employeeCode", {
                  required: "Employee Code is required",
                })}
                error={!!errors.employeeCode}
                helperText={errors.employeeCode?.message}
              />
            </Grid>

            {/* Email */}
            <Grid item xs={6}>
              <TextField
                label="Email *"
                fullWidth
                {...register("emailID", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email format",
                  },
                })}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            </Grid>

            {/* Mobile Number */}
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel>Select Country</InputLabel>
                <Controller
                  name="countryCode"
                  control={control}
                  defaultValue="91"
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="91">India</MenuItem>
                      <MenuItem value="6">USA</MenuItem>
                      <MenuItem value="10">UK</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <TextField
                label="Mobile Number"
                fullWidth
                {...register("mobile", {
                  required: "Mobile Number is required",
                  pattern: {
                    value: /^[0-9]{10}$/,
                    message: "Invalid mobile number",
                  },
                })}
                error={!!errors.mobile}
                helperText={errors.mobile?.message}
              />
            </Grid>

            {/* Reports To */}
            <Grid item xs={6}>
              <TextField
                label="Reports To"
                fullWidth
                {...register("managerEmail")}
              />
            </Grid>

            {/* Designation */}
            <Grid item xs={6}>
              <TextField
                label="Designation *"
                fullWidth
                {...register("designation", {
                  required: "Designation is required",
                })}
                error={!!errors.designation}
                helperText={errors.designation?.message}
              />
            </Grid>

            {/* Department */}
            <Grid item xs={6}>
              <TextField
                label="Department *"
                fullWidth
                {...register("department", {
                  required: "Department is required",
                })}
                error={!!errors.department}
                helperText={errors.department?.message}
              />
            </Grid>

            {/* Office Location */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="office-location-label">Office Location</InputLabel>
                <Controller
                  name="officeLocation"
                  control={control}
                  defaultValue="Motivity Labs, Hyderabad"
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="Motivity Labs, Hyderabad">
                        Motivity Labs, Hyderabad
                      </MenuItem>
                      <MenuItem value="Motivity Labs, USA">Motivity Labs, USA</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>

            {/* Work Location */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="work-location-label">Work Location</InputLabel>
                <Controller
                  name="workLocation"
                  control={control}
                  defaultValue="Hyderabad"
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="Hyderabad">
                        Hyderabad
                      </MenuItem>
                      <MenuItem value="Dallas, USA">Dallas, USA</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>

            {/* Country Dropdown */}

            {/* Gender */}
            {/* <Grid item xs={3}>
              <FormControl component="fieldset">
                Gender: 
                <Controller
                  name="gender"
                  control={control}
                  rules={{ required: "Gender is required" }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="Male"
                        control={<Radio />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="Female"
                        control={<Radio />}
                        label="Female"
                      />
                    </RadioGroup>
                  )}
                />
                {errors.gender && (
                  <Typography color="error">{errors.gender.message}</Typography>
                )}
              </FormControl>
            </Grid> */}
          </Grid>

          <DialogActions>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddEmployeeForm;
