import { Box, Button, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import moment from "moment";
import "./style.css";
import useAxios from "../../helpers/Hooks/useAxios";
import { URLS } from "../../helpers/URLS";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const EmpTimeSheetReport = ({ reportData = [] }) => {
  const [datesMap, setDatesMap] = useState({});
  const [assignedProjectList, setAssignedProjectList] = useState([]);
  const [activitiesList, setActivitiesList] = useState([]);
  const [comments , setComments]=useState({})
  // const [user,setUser]=useState()

  const { fetchData } = useAxios();
  // const navigate = useNavigate();

  const getDatesOfTheReport = (value) => {
    try {
      let valueee = moment(value, "DD-MM-YYYY").toDate();
      let modifiedStartDate = new Date(valueee);
      let modifiedEndDate = new Date(modifiedStartDate);
      modifiedEndDate.setDate(modifiedStartDate.getDate() + 6);

      let datess = Array.from({ length: 7 }).map((_, i) => {
        let nextDate = new Date(modifiedStartDate);
        nextDate.setDate(nextDate.getDate() + i);
        return nextDate;
      });

      datess = datess.sort((a, b) => new Date(a) - new Date(b));
      return datess || [];
    } catch (error) {
      console.error(error, "error");
      return [];
    }
  };

  const getAssignedProjectsList = async () => {
    try {
      const resp = await fetchData({
        url: URLS.getAssignedProjects,
        method: "get",
      });
      if (resp.status === 200) {
        setAssignedProjectList(resp.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getActivitiesList = async () => {
    try {
      const resp = await fetchData({ url: URLS.getActivities, method: "get" });
      if (resp.status === 200) {
        setActivitiesList(resp.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProjActivity = (activityID) => {
    let ActivityInfo = activitiesList?.find((each) => each.value == activityID);
    return ActivityInfo?._doc?.activityName || "";
  };

  const getProjectName = (projectID) => {
    // console.log(projectID , 'projectID')
    let projectInfo = assignedProjectList?.find(
      (each) => each.projectId == projectID
    );
    return projectInfo?.projectName || null;
  };
  const converHoursFormat = (value) => {
    if (!value) {
      return null;
    }
    if (parseInt(value) < 9) {
      if (value?.length > 1) {
        return `${value}:00`;
      }
      return `0${value}:00`;
    } else {
      return `${value}:00`;
    }
  };

//   const getUserProfle = async () => {
//     const resp = await fetchData({ url: URLS.user, method: 'get' });
//     if (resp.status === 200) {
//         setUser(resp.data)
//     } else {
//         navigate("/")
//     }
// }


  useEffect(() => {
    getAssignedProjectsList();
    getActivitiesList();
    // getUserProfle()
  }, []);

  useEffect(() => {
    const reportDates = {};
    reportData.forEach((report) => {
      reportDates[report.startDate] = getDatesOfTheReport(report.startDate);
    });
    setDatesMap(reportDates);
    // console.log(reportData, "reportData");
  }, [reportData]);

const handleComment =(reportId, comment)=>{
    let reportsComment={};
     reportsComment[reportId]=comment

     console.log(reportsComment , 'reportsComme')
     setComments({...comments , ...reportsComment})
     console.log(comments)
}





const handleApprove = async(id , isApprove)=>{
    try{

if(!isApprove && !comments[id] ){
// alert("please add comment")
toast.error('Please add comment')
return 
}


let bodyObj={timesheetIds:[id], 
approverComment:comments[id],
approvalStatus:isApprove?'Approved':'Rejected'
}

      let approvedRes =  await fetchData({url:URLS.updateTimeSheet , method:"put" , body:bodyObj})
      if(approvedRes.status==200){
      }
    }
    catch(error){
    console.log(error , 'ap btn')
    }
  }



  return (
    <Container>
      {reportData.length > 0 &&
        reportData.map((report, index) => (
          <Box key={index}>
            <Typography>
              {report?.startDate} - {report?.endDate}
            </Typography>
            <Box className="card mb-4 timesheet-card">
              <Box className="timesheet-container">
                <Box className="date-map-cls">
                  {datesMap[report.startDate]?.map((date) => (
                    <Box className="show-dates" key={`${new Date(date)}`}>
                      <Typography>
                        {moment(new Date(date)).format("ddd")}
                      </Typography>
                      <Typography>
                        {moment(new Date(date)).format("DD-MM-YYYY")}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Box className="reports-container">
                  <Box className="report-cards-count">
                    {report?.report
                      .sort((a, b) => new Date(a.date) - new Date(b.date))
                      ?.map((each) => {
                        return (
                          <Box className="day-wise-reportCard">
                            {each?.report?.map((rep) => {
                              return (
                               <Box className="repor-card-map">
                                  {(getProjectName(rep?.projectID) &&
                                  (rep?.activityID ||
                                  rep?.description))
                                     ? (
                                    <Box
                                      sx={{ height: 110, width: 220 }}
                                      className={`${
                                        moment(new Date(each.date)).format(
                                          "ddd"
                                        ) == "Sun" ||
                                        moment(new Date(each.date)).format(
                                          "ddd"
                                        ) === "Sat"
                                          ? "BackgroundClr"
                                          : ""
                                      } report-card`}
                                    >
                                      <Box className="d-flex justify-content-between">
                                        <Typography
                                          sx={{
                                            fontSize: 12,
                                            // fontFamily:'"Jost", sans-serif',
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {getProjectName(rep?.projectID)}
                                        </Typography>
                                        <Typography className="time-hours">
                                          {converHoursFormat(rep?.hours)}
                                        </Typography>
                                      </Box>
                                      <Tooltip title={rep?.description}>
                                        <TextField
                                          sx={{
                                            "& .MuiOutlinedInput-root": {
                                              padding: 0,
                                              margin: 0,
                                              "& fieldset": {
                                                border: "none",
                                              },
                                              "&:hover fieldset": {
                                                border: "none",
                                              },
                                              "&.Mui-focused fieldset": {
                                                border: "none",
                                              },
                                              "& input": {
                                                padding: 0,
                                              },
                                            },
                                            "& .MuiInputBase-input": {
                                              padding: 0,
                                            },
                                            margin: 0,
                                            width: "100%",
                                          }}
                                          defaultValue={rep?.description || ""}
                                          value={rep?.description || ""}
                                          multiline
                                          inputProps={{
                                            style: {
                                              height: "50px",
                                              fontSize: 12,
                                              fontWeight: 500,
                                              fontFamily: '"Figtree", serif '
                                            },
                                            className: "day-work-status",
                                          }}
                                        />
                                      </Tooltip>
                                      <Box className="d-flex justify-content-between">
                                        {rep?.activityID ? (
                                          <Box className="activity-Box">
                                            <Typography className="activity-typo">
                                              {getProjActivity(rep?.activityID)}
                                            </Typography>
                                          </Box>
                                        ) : (
                                          <Box></Box>
                                        )}
                                        {/* {getDefaultValue(date, index) && !existingReport && (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          onClick={() =>
                                            handleEditTimeSheetClick({
                                              data: getDefaultValue(
                                                date,
                                                index
                                              ),
                                              selectedReportIndex: index
                                            })
                                          }
                                          style={{ cursor: 'pointer' }}
                                        >
                                          <path
                                            d="M12.5007 5.00007L15.0007 7.50007M10.834 16.6667H17.5007M4.16732 13.3334L3.33398 16.6667L6.66732 15.8334L16.3223 6.17841C16.6348 5.86586 16.8103 5.44201 16.8103 5.00007C16.8103 4.55813 16.6348 4.13429 16.3223 3.82174L16.179 3.67841C15.8664 3.36596 15.4426 3.19043 15.0007 3.19043C14.5587 3.19043 14.1349 3.36596 13.8223 3.67841L4.16732 13.3334Z"
                                            stroke="#A1A1A1"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      )} */}
                                      </Box>
                                    </Box>
                                  ) : (
                                    <Box
                                      sx={{ height: 110 }}
                                      className={`${
                                        moment(new Date(each.date)).format(
                                          "ddd"
                                        ) == "Sun" ||
                                        moment(new Date(each.date)).format(
                                          "ddd"
                                        ) === "Sat"
                                          ? "empty-BackgroundClr"
                                          : "empty-card"
                                      } report-card`}
                                    ></Box>
                                  )}
                                </Box>
                              );
                            })}
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              </Box>
              <Box className="approve-section-cls">
                <TextField
                  label="comment"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: 0,
                      paddingLeft: 1,
                      margin: 0,
                      width: "400px",
                      height: "40px",
                    },
                    "& .MuiInputBase-input": {
                      padding: 0,
                    },
                    margin: 0,
                  }}
                  defaultValue=''
                  value={comments && comments[report?._id]}
                  onChange={(e)=>handleComment(report?._id , e.target.value)}
                  multiline
                />

                <Box className="submit-btn">
                <Button className="rejbtn" size="small" onClick={()=>handleApprove(report?._id , false)}>
                    Reject
                  </Button>
                </Box>
                <Box className="submit-btn">
                  <Button className="sbtn" size="small" onClick={()=>handleApprove(report?._id , true)}>
                    Approve
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
    </Container>
  );
};

export default EmpTimeSheetReport;
