import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { URLS } from "../../helpers/URLS";
import useAxios from "../../helpers/Hooks/useAxios";
import SkeletonLoader from "../../loaders/Skeleton";
import NoData from "../../loaders/NoData";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TablePagination,
  TableSortLabel,
  IconButton,
  Box,
  Typography,
  Toolbar
} from "@mui/material";
import { Edit, Delete, Visibility, Add } from "@mui/icons-material";
import AddEmployeeForm from "../../components/AddEmployee/AddEmployee";

// Sorting functions
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, comparator) {
  return array.slice().sort(comparator);
}

const columns = [
  { id: "employeeCode", label: "ID" },
  { id: "employeeName", label: "Name" },
  { id: "emailID", label: "Email" },
  { id: "department", label: "Department" },
  { id: "designation", label: "Designation" },
  { id: "managerName", label: "Manager Name" },
  { id: "action", label: "Action", sortable: false },
];

const ManageEmployees = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const { fetchData } = useAxios();
  const [employeeList, setemployeeList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loadSkeleton, setLoadSkeleton] = useState(true);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [rows, setRows] = useState([]);
  const [openAddEmployee, setOpenAddEmployee] = useState(false);

  useEffect(() => {
    getEmployeeList();
  }, []);

  const getEmployeeList = async () => {
    setLoadSkeleton(true);
    const resp = await fetchData({ url: URLS.getEmployees, method: "get" });
    if (resp.status === 200) {
      setemployeeList(resp.data);
      setLoadSkeleton(false);
      setRows(resp.data);
      console.log(resp.data);
    }
  };

  const handleOpen = () => {
    setOpenAddEmployee(true);
  };

  const handleClose = () => {
    setOpenAddEmployee(false);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setPage(0);
  };

  const filteredRows = rows.filter((row) =>
    Object.values(row).some((val) =>
      String(val).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);
  };

  const sortedRows = stableSort(filteredRows, getComparator(order, orderBy));
  const paginatedRows = sortedRows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ padding: "20px" }}>
        <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          marginBottom: "20px",
          paddingLeft: "0px"
        }}
      >
      <Typography variant="h5" sx={{ marginBottom: "16px", fontWeight: "bold" }}>
        Manage Employees
      </Typography>

      {/* Search Bar */}
      
        <TextField
          label="Search Employee"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearch}
          sx={{ maxWidth: "400px", backgroundColor: "#fff", borderRadius: "5px" }}
        />
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#1976d2",
            color: "#fff",
            "&:hover": { backgroundColor: "#2e2b44" },
          }}
          startIcon={<Add />}
          onClick={handleOpen}
        >
          Add Employee
        </Button>
      </Toolbar>
      <Box>
        <AddEmployeeForm open={openAddEmployee} onClose={handleClose} />
      </Box>

      {loadSkeleton ? (
        <SkeletonLoader />
      ) : employeeList.length > 0 ? (
        <>
          <TableContainer component={Paper} sx={{ borderRadius: "8px", overflow: "hidden" }}>
            <Table>
              {/* Table Head */}
              <TableHead sx={{ backgroundColor: "#E0E9F9" }}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        fontWeight: "bold",
                        padding: "12px",
                        whiteSpace: "nowrap",
                        textAlign: "left",
                      }}
                      sortDirection={orderBy === column.id ? order : false}
                    >
                      {column.sortable !== false ? (
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : "asc"}
                          onClick={() => handleRequestSort(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      ) : (
                        column.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {/* Table Body */}
              <TableBody>
                {paginatedRows.map((row) => (
                  <TableRow key={row.employeeCode}>
                    <TableCell>{row.employeeCode}</TableCell>
                    <TableCell>{row.employeeName}</TableCell>
                    <TableCell>{row.emailID}</TableCell>
                    <TableCell>{row.department}</TableCell>
                    <TableCell>{row.designation}</TableCell>
                    <TableCell>{row.managerName}</TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", gap: "8px" }}>
                        <IconButton
                          sx={{
                            backgroundColor: "#ffffff",
                            border: "1px solid #ddd",
                            borderRadius: "6px",
                            padding: "6px",
                          }}
                        >
                          <Visibility />
                        </IconButton>
                        <IconButton
                          sx={{
                            backgroundColor: "#ffffff",
                            border: "1px solid #ddd",
                            borderRadius: "6px",
                            padding: "6px",
                          }}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          sx={{
                            backgroundColor: "#ffffff",
                            border: "1px solid #ddd",
                            borderRadius: "6px",
                            padding: "6px",
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}

                {/* No Data Found */}
                {paginatedRows.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No matching records found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination */}
          <TablePagination
            component="div"
            count={filteredRows.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <NoData />
      )}
    </Box>
  );
};

export default ManageEmployees;
