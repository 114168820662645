import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { TextField, Grid, Button, Tooltip, MenuItem, Box , IconButton , Popover } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { URLS } from "../../helpers/URLS";
import moment, { now } from "moment";
import useAxios from "../../helpers/Hooks/useAxios";

const AddStatus = ({ isStatusModel, modelHandle, statusPayload, setStatusPayload, statusObj, assignedProjectList, activitiesList, getDailyStatus,
    disabledDate = false, showAddRemoveBtns = true, handleTimeSheetEdit = () => { }, isAddNewTimeSheet = false, handleSaveNewTimeSheet = () => { }, isAddNewProject = false, handleSaveNewProject = () => { } }) => {

    useEffect(() => {
        handleTimeSheetEdit(statusPayload)
    }, [statusPayload])

    const paylaodData = statusPayload?.List[0]
    // console.log(statusPayload?.List[0], 'statusPayload?.List[0]')
const shouldDisableSubmitBtn = (data)=>{
    try{
        //console.log(data, 'data')
    let result = data?.List?.every((each)=> each?.projectID && each?.activityID && each?.description && parseInt(each?.hours)>=1)
    return !result
    }
    catch(error){
        return false
    }
}



const [anchorEl, setAnchorEl] = useState(null);

  // Local state to hold hours and minutes (as numbers)
  const [hours, setHours] = useState('00');
  const [minutes, setMinutes] = useState('00');

  // Parse initial value from row.hours (e.g., "02:30") when component mounts or row.hours changes
//   useEffect(() => {
//     if (row.hours) {
//       const [hStr, mStr] = row.hours.split(":");
//       setHours(parseInt(hStr, 10) || 0);
//       setMinutes(parseInt(mStr, 10) || 0);
//     }
//   }, [row.hours]);

  // Open popover
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // Increment/Decrement handlers
  const incrementHours = (index) => {
    setHours((prev) => {
      const newVal = Math.min((parseInt(statusPayload?.List[index].hours) + 1), 8); // clamp to 8
      // If hours = 8, we could also force minutes to 0 if you want strictly 8:00
      if(newVal==8){
        setMinutes("00")
      }
     

      setStatusPayload({ ...statusPayload, List: statusPayload.List.map((item, i) => i === index ? { ...item, hours: newVal } : item) });
      return "0"+newVal;
    });
  };

  const decrementHours = (index) => {
    setHours((prev) => {
      const newVal = Math.max(parseInt((parseInt(statusPayload?.List[index].hours) + 1)) - 1, 0);
      setStatusPayload({ ...statusPayload, List: statusPayload.List.map((item, i) => i === index ? { ...item, hours: newVal } : item) });

      return "0"+newVal;
    });
  };

  const incrementMinutes = () => {
    setMinutes((prev) => {
      let newVal = parseInt(prev) + 1;
      if(newVal<10){
        return "0"+ newVal
      }
      if (parseInt(newVal) > 59) {
        // roll over to next hour, if under 8 hours
        newVal= "0"
        if (parseInt(hours) < 8) {
            
          setHours("0"+(parseInt(hours) + 1));
        }
        
      }
      return parseInt(newVal)<10 ? "0"+newVal : newVal;
    });
  };

  const decrementMinutes = () => {
    setMinutes((prev) => {
      let newVal = parseInt(prev) - 1;
      if (newVal < 0) {
        if (parseInt(hours) > 0) {
          setHours("0"+ parseInt(hours - 1));
          newVal = 59;
        } else {
          newVal = "0"; 
        }
      }
      return parseInt(newVal)<10 ? "0"+newVal : newVal;
    });
  };


  const parseAndSetTime = (value) => {
  
    const match = /^(\d{1,2})(?::(\d{1,2}))?$/.exec(value.trim());
    if (match) {
      let h = parseInt(match[1] , "00");
      let m = parseInt(match[2] , "00");

      // Clamp to 8:00 if hours exceed 8
      if (parseInt(h) > 8) {
    //  alert(125)
        h = "08";
        m = "00";
        
      } 
      else if(parseInt(m)>59 && parseInt(h) < 8 ){
    //    alert(131)
        h=parseInt(h)+1 
        h='0'+h
        m=parseInt(m)-60 <10 ? '0'+parseInt(m)-60 : parseInt(m)-60

            if(parseInt(h) >= 8  ){
                setHours('08')
                setMinutes('00')
            }

        }
      
      else if (parseInt(h) === 8 && parseInt(m) > 0) {
        //  alert("138")
       
        m = "00";
      }
      else if (parseInt(h) >= 8 && parseInt(m) >= 59){
        // alert("142")
        setHours('08')
        setMinutes('00')
      }
   

      setHours(h);
      setMinutes(m);
    }
    else{
    //   alert("150")
       let values = value.split(':')
      if(parseInt(values[0]) > 8){
        setHours("08");
        setMinutes('00')
      }
      else if(parseInt(values[1])>59 && parseInt(values[0]) < 8){
        //  alert("157")
       let h=parseInt(values[0])+1 
        h='0'+h
      let  m=parseInt(m)-60 <10 ? '0'+parseInt(m)-60 : parseInt(m)-60
      setHours(h);
      setMinutes(m);
      return 
       }
        // alert("165")
    //    setHours(values[0]=="" ? "00" : parseInt(values[0]));
    //     setMinutes(values[1]=="" ? "00" : parseInt(values[1]));
    }
  };


  const handleManualChange = (e, index) => {
    //console.log(index , 'from handle manual change')
    const inputValue = e.target.value;
    parseAndSetTime(inputValue); // update local hours/minutes

    const list = [...statusPayload.List];
    list[index].hours = inputValue;
    setStatusPayload({ ...statusPayload, List: list });
  };


    const { fetchData } = useAxios();
    return (
        <div>
            <Modal size="lg" show={isStatusModel} onHide={modelHandle} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='work-report-section bg-white'>
                        <div className="">
                            {!isAddNewProject && <><div className="d-flex justify-content-between align-items-center pe-3">
                                <div className="form-group">
                                    <Grid item xs={6} sx={{ padding: 2 }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                                            <DesktopDatePicker
                                                label="Date"
                                                inputFormat="DD/MM/YYYY"
                                                value={statusPayload.date}
                                                maxDate={new Date()}
                                                onChange={(date) => { setStatusPayload({ ...statusPayload, date: new Date(date) }) }}
                                                renderInput={(params) => <TextField {...params} fullWidth required />}
                                                disabled={disabledDate}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </div>
                                {showAddRemoveBtns && <div className="">
                                    <div className='text-end'>
                                        <Tooltip title="Add New Report">
                                            <button onClick={(e) => {
                                                const list = [...statusPayload.List];
                                                list.push({ ...statusObj });
                                                setStatusPayload({ ...statusPayload, List: list })
                                            }} className='btn btn-success rounded-circle'>+</button>
                                        </Tooltip>
                                    </div>
                                </div>}
                            </div>
                                <hr />
                            </>}
                            <div className="modal_scroll px-3">
                                {statusPayload.List.map((row, index) => <div key={index} className="border-bottom py-3">
                                    {showAddRemoveBtns && <div className='text-end'>
                                        <Tooltip title="Delete Status Report" placement="top" arrow>
                                            <button onClick={(e) => {
                                                const list = [...statusPayload.List];
                                                list.splice(index, 1);
                                                setStatusPayload({ ...statusPayload, List: list })
                                            }} className='btn btn-danger rounded-circle'>---</button>
                                        </Tooltip>
                                    </div>}
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <TextField sx={{ mt: 3 }} select label={'Project'} fullWidth size="small"
                                                    value={statusPayload.dailystatusID ? row.projectID : assignedProjectList.find(x => x.value === row.projectID)?.value}
                                                    onChange={(e) => {
                                                        const list = [...statusPayload.List];
                                                        list[index].projectID = e.target.value;
                                                        list[index].activityID = "";
                                                        setStatusPayload({ ...statusPayload, List: list })
                                                    }}
                                                >
                                                    {
                                                        assignedProjectList.map((item, index) =>
                                                            <MenuItem key={index} value={item.value} disabled={new Date(moment(item.projectEndDate).format('L')) >= new Date(moment(now()).format('L')) ? false : true}>{item.label}</MenuItem>
                                                        )
                                                    }
                                                </TextField>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <TextField sx={{ mt: 3 }} select label={'Activity'} fullWidth size="small"
                                                    value={statusPayload.dailystatusID ? row.activityID : activitiesList.find(x => x.value === row.activityID)?.value}
                                                    disabled={row.projectID === '' ? true : false}
                                                    onChange={((e) => {
                                                        const list = [...statusPayload.List];
                                                        list[index].activityID = e.target.value;
                                                        setStatusPayload({ ...statusPayload, List: list })
                                                    })}
                                                >
                                                    {
                                                        activitiesList.map((item, index) =>

                                                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                                        )
                                                    }
                                                </TextField>
                                            </div>
                                        </div>
                                        <div className="col-md-4 ">
                                            <div className="form-group">
                                                
                                                {/* <TextField 
                                                sx={{ mt: 3 }} id="outlined-basic" fullWidth   variant="outlined" placeholder="00:00" type="time" 
                                                inputProps={{
                                                    min: "00:00",
                                                    max: "08:00",
                                                  }}
                                                    value={row.hours}
                                                    //  onKeyDown={(event) => {
                                                    
                                                    //      if (!/[0-9]/.test(event.key) || parseInt(event.target.value>8)) {
                                                    //        event.preventDefault();
                                                    //     }
                                                    //  }} 
                                                    onChange={(obj) => {
                                                        const list = [...statusPayload.List];
                                                        list[index].hours = obj.target.value;
                                                        setStatusPayload({ ...statusPayload, List: list })
                                                    }} /> */}


                                            <TextField
                                                label='Hours'
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder="00:00"
                                                    
                                                value={`${String(statusPayload?.List[index]?.hours).padStart(2, "0")}:${minutes}`}
                                                    onClick={handleOpen}
                                                    // onChange={(e)=>handleManualChange(e,index)}
                                                    // InputProps={{ readOnly: true }}
                                                    sx={{ mt: 3, cursor: "pointer" }}
                                                />

                                                {/* Popover with arrow buttons for hours and minutes */}
                                                <Popover
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                    }}
                                                >
                                                    <Box p={2} display="flex" flexDirection="column" alignItems="center">
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        {/* Hours Column */}
                                                        <Box textAlign="center" mx={1}>
                                                        <IconButton onClick={()=>incrementHours(index)}>
                                                            <ArrowDropUp />
                                                        </IconButton>
                                                        <Box>{String(statusPayload?.List[index]?.hours).padStart(2, "0")}</Box>
                                                        <IconButton onClick={()=>decrementHours(index)}>
                                                            <ArrowDropDown />
                                                        </IconButton>
                                                        </Box>

                                                        {/* Separator */}
                                                        <Box mx={1} fontSize="1.5rem">
                                                        :
                                                        </Box>

                                                        {/* Minutes Column */}
                                                        <Box textAlign="center" mx={1}>
                                                        <IconButton 
                                                        onClick={incrementMinutes}  disabled
                                                        //</Box>disabled={parseInt(hours)>=8}
                                                         >
                                                            <ArrowDropUp />
                                                        </IconButton>
                                                        <Box>{String(minutes).padStart(2, "0")}</Box>
                                                        <IconButton disabled={true}
                                                         onClick={decrementMinutes}
                                                        >
                                                            <ArrowDropDown />
                                                        </IconButton>
                                                        </Box>
                                                    </Box>
                                                    </Box>
                                                </Popover>




                        
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <div className="form-group">
                                                <TextField sx={{ mt: 3 }} id="outlined-basic" rows={2} multiline label="Description" variant="outlined" fullWidth
                                                    value={row.description}
                                                    onChange={(obj) => {
                                                        const list = [...statusPayload.List];
                                                        list[index].description = obj.target.value;
                                                        setStatusPayload({ ...statusPayload, List: list })
                                                    }}
                                                    // disabled={isAddNewProject}
                                                    className="day-report-status-desc"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                {showAddRemoveBtns && <Modal.Footer>
                    <Button variant="contained" color='inherit' onClick={modelHandle} className="me-3">
                        Close
                    </Button>
                    <Button variant="contained" 
                    disabled={shouldDisableSubmitBtn(statusPayload)}
                        onClick={async (e) => {
                            const payload = {
                                ...statusPayload,
                                date: moment(statusPayload.date).format('YYYY-MM-DD')
                            };
                           
                            let workhours=0;
                            payload.List.forEach((item) => {
                                workhours += parseInt(item.hours);
                            });
                            if(workhours > 8) {
                                toast.error("Work hours should not exceed 8 hours")
                                return
                            }

                            const resp = await fetchData({ url: URLS.manageDailyStatus, method: 'post', body: payload });
                            if (resp.status === 200) {
                                toast.success(resp.message)
                                modelHandle();
                                getDailyStatus();
                            }
                            else {
                                toast.error(resp.message)
                            }
                        }} >
                        {statusPayload.dailystatusID ? "Update" : "Save"}
                    </Button>
                </Modal.Footer>
                }
                {isAddNewTimeSheet && <Modal.Footer>
                    <Button variant="contained" onClick={() => {
                        if (isAddNewProject) {
                            handleSaveNewProject(statusPayload)
                        } else {
                            handleSaveNewTimeSheet(statusPayload)
                        }
                        modelHandle()
                    }}
                        disabled={(paylaodData?.projectID && paylaodData?.activityID && paylaodData?.hours) ? false : true}
                    >
                        Save
                    </Button>
                </Modal.Footer>
                }
            </Modal>
        </div>
    )
}

export default AddStatus
