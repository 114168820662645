// import React, { useState, useEffect } from "react";
// import { Modal } from "react-bootstrap";
// import { toast } from "react-toastify";
// import { TextField, Grid, Button, Tooltip, MenuItem, IconButton } from "@mui/material";
// import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
// import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
// import useAxios from "../../helpers/Hooks/useAxios";
// import { URLS } from "../../helpers/URLS";

// const ViewAssetsModal = ({ isStatusModel = false, modelHandle = () => { }, selectedEmployee = null }) => {
//     const [assets, setAssets] = useState([]);
//     const { fetchData } = useAxios();
//     const [checkedAssets, setCheckedAssets] = useState({});
//     const [AddNewAssets, setAddNewAssets] = useState(null);


//     const handleCheckboxChange = (assetName) => {
//         setCheckedAssets((prevState) => ({
//             ...prevState,
//             [assetName]: !prevState[assetName],
//         }));
//     };


//     useEffect(() => {
//         const assetsList = async () => {
//             const response = await fetchData({
//                 url: URLS.getAssetsList,
//                 method: "get",
//             });
//             // console.log(response, "response response")
//             const requiredAssetsState = {}
//             response?.Items?.forEach((asset) => {
//                 requiredAssetsState[asset?.ItemName] = []
//             })
//             setAssets(response?.Items || []);
//             setAddNewAssets(requiredAssetsState)
//             // setFilteredEmployees(response?.data || [])
//         };
//         assetsList();
//     }, []);

//     const getExistingDetails = (asset) => {
//         const userAssetsData = selectedEmployee?.Assets || []
//         const getRequiredAssetsData = userAssetsData?.filter((eachAsset) => eachAsset?.item?.toLowerCase() === asset)
//         return (
//             <ol className="existing-assets-list-cls">
//                 {getRequiredAssetsData?.map((eachOne) => {
//                     const keysList = Object.keys(eachOne)?.filter((eachkey) => eachkey?.toLowerCase() !== '_id' && eachkey?.toLowerCase() !== 'item')
//                     return (
//                         <li key={eachOne?._id}>
//                             {keysList?.map((key) => <p key={eachOne[key] + eachOne?._id}><span style={{ fontWeight: 600 }}>{key}</span>: {`${eachOne[key]}`}</p>)}
//                         </li>
//                     )
//                 })}
//             </ol>
//         )
//     }

//     console.log(AddNewAssets, "AddNewAssets AddNewAssets")
//     return (
//         <div>
//             <Modal size="lg" show={isStatusModel} onHide={modelHandle} backdrop="static">
//                 <Modal.Header closeButton>
//                     <Modal.Title style={{ fontSize: '1.4rem' }}>{selectedEmployee ? selectedEmployee?.employeeName : ''} Assets</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     {/* <p className="my-3 text-center"> Currenly Under Development</p> */}
//                     <div className="asset-list-container">
//                         <ul className="asset-list">
//                             {assets.map((eachAsset, i) => (
//                                 <li key={eachAsset.ItemName + i} className="asset-item">
//                                     <label className="asset-label">
//                                         <input
//                                             type="checkbox"
//                                             className="asset-checkbox"
//                                             checked={!!checkedAssets[eachAsset.ItemName]}
//                                             onChange={() => handleCheckboxChange(eachAsset.ItemName)}
//                                         />
//                                         <span className="custom-checkbox"></span>
//                                         {eachAsset.ItemName}
//                                         {!!checkedAssets[eachAsset.ItemName] && <IconButton className="add-asset-btn-cls"> <AddCircleRoundedIcon sx={{ color: "#54C154" }} /></IconButton>}
//                                     </label>

//                                     {checkedAssets[eachAsset.ItemName] && (
//                                         <div className="asset-details">
//                                             <p style={{ fontWeight: 600, fontSize: '0.9rem' }}>
//                                                 Existing Details: {' '}
//                                                 {/* <strong>Existing Details:</strong>{" "} */}
//                                                 {/* <span className="text-muted"> */}
//                                                 {/* {eachAsset.details || "No existing details available"} */}
//                                                 {/* {getExistingDetails(eachAsset?.ItemName?.toLowerCase())} */}
//                                                 {/* </span> */}
//                                             </p>
//                                             {getExistingDetails(eachAsset?.ItemName?.toLowerCase())}
// <label>
//     <p style={{ fontWeight: 600, fontSize: '0.9rem' }}>New Serial Number:</p>
//     <input
//         type="text"
//         placeholder="Enter serial number"
//         className="serial-input"
//         size={'small'}
//     />
// </label>
//                                         </div>
//                                     )}
//                                 </li>
//                             ))}
//                         </ul>
//                     </div>
//                 </Modal.Body>

//                 <Modal.Footer>
//                     <Button variant="contained"
//                         disabled
//                     >
//                         Save
//                     </Button>
//                 </Modal.Footer>

//             </Modal>
//         </div>
//     )
// }

// export default ViewAssetsModal


import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { TextField, Button, IconButton } from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import useAxios from "../../helpers/Hooks/useAxios";
import { URLS } from "../../helpers/URLS";
import moment from 'moment'


const ViewAssetsModal = ({ isStatusModel = false, modelHandle = () => { }, selectedEmployee = null, getEmployeeList = () => { } }) => {
    const [assets, setAssets] = useState([]);
    const { fetchData } = useAxios();
    const [checkedAssets, setCheckedAssets] = useState({});
    const [AddNewAssets, setAddNewAssets] = useState({});
    const [isSaveBtnActive, setIsSaveBtnActive] = useState(false);


    const handleCheckboxChange = (assetName) => {
        setCheckedAssets((prevState) => ({
            ...prevState,
            [assetName]: !prevState[assetName],
        }));

        if (!checkedAssets[assetName]) {
            setAddNewAssets((prevState) => ({
                ...prevState,
                [assetName]: [],
            }));
        } else {
            setAddNewAssets((prevState) => {
                const updatedState = { ...prevState };
                delete updatedState[assetName];
                return updatedState;
            });
        }
    };

    const handleAddSerialInput = (assetName) => {
        setAddNewAssets((prevState) => ({
            ...prevState,
            [assetName]: [...(prevState[assetName] || []), ""],
        }));
    };

    const handleSerialNumberChange = (assetName, index, value) => {
        setAddNewAssets((prevState) => {
            const updatedSerials = [...(prevState[assetName] || [])];
            // console.log(updatedSerials, "updatedSerialsv")
            updatedSerials[index] = { 'ItemName': assetName, 'serial_no': value, };
            // console.log(updatedSerials,"updatedSerialsv")
            // console.log(updatedSerials, "updatedSerialsv updatedSerialsv")

            return {
                ...prevState,
                [assetName]: updatedSerials,
            };
        });

    };

    const handleSaveAssets = async () => {
        const newAssetsKeys = Object.keys(AddNewAssets)
        let requiredAssetsFormat = []
        newAssetsKeys && newAssetsKeys?.length > 0 && newAssetsKeys?.forEach((key) => {
            requiredAssetsFormat = [...requiredAssetsFormat, ...AddNewAssets[key]]
        })
        const payload = {
            userId: selectedEmployee?._id,
            assets: requiredAssetsFormat,
        };
        try {
            await fetchData({
                url: URLS.updateEmpAssets,
                method: "put",
                body: payload,
            });
            toast.success("Assets saved successfully!");
            modelHandle();
            getEmployeeList();
        } catch (error) {
            toast.error("Failed to save assets. Please try again.");
        }
        // console.log(payload, "payload payload")
    };

    const getUpdateCheckBoxStatus = () => {
        const userAssetsData = selectedEmployee?.Assets || [];
        // console.log(userAssetsData, "userAssetsData userAssetsData");
        // console.log(assets, "assets");

        assets.forEach((asset) => {
            const isAssetExist = userAssetsData.some((data) => data?.item === asset?.ItemName)
            if (isAssetExist) {
                handleCheckboxChange(asset?.ItemName)
            }
        })

    }

    useEffect(() => {
        const assetsList = async () => {
            const response = await fetchData({
                url: URLS.getAssetsList,
                method: "get",
            });
            setAssets(response?.Items || []);
        };
        assetsList();
    }, []);
    useEffect(() => {
        getUpdateCheckBoxStatus();
    }, [assets]);
    useEffect(() => {
        getDisabledStatus();
    }, [AddNewAssets]);

    const getExistingDetails = (asset) => {
        const userAssetsData = selectedEmployee?.Assets || [];
        const getRequiredAssetsData = userAssetsData?.filter(
            (eachAsset) => eachAsset?.item?.toLowerCase() === asset
        );
        return (
            <>
                <ol className="existing-assets-list-cls">
                    {getRequiredAssetsData?.length > 0 ? getRequiredAssetsData?.map((eachOne) => {
                        const keysList = Object.keys(eachOne)
                        // ?.filter(
                        //     (eachkey) =>
                        //         eachkey?.toLowerCase() !== "_id" &&
                        //         eachkey?.toLowerCase() !== "item"
                        // );
                        return (
                            <li key={eachOne?._id}>
                                {/* {keysList?.map((key) => (
                                    <p key={eachOne[key] + eachOne?._id}>
                                        <span style={{ fontWeight: 600 }}>{key}</span>: {`${eachOne[key]}`}
                                    </p>
                                ))} */}
                                <div className="">
                                    <p className=" ">
                                        <span style={{ fontWeight: 600 }}>Serial No</span>: {`${eachOne["serial_no"]}`}
                                    </p>
                                    <p className=" ">
                                        <span style={{ fontWeight: 600 }}>Provide By</span>: {`${eachOne["provideBy"]}`}
                                    </p>
                                    <p className=" ">
                                        <span style={{ fontWeight: 600 }}>Issued Date</span>: {`${moment(new Date(eachOne["issued_date"])).format("DD-MM-YYYY") == 'Invalid date' ? eachOne["issued_date"] : moment(new Date(eachOne["issued_date"])).format("DD-MM-YYYY")}`}
                                    </p>
                                    <p className=" ">
                                        <span style={{ fontWeight: 600 }}>Returned</span>:
                                        {/* {`${eachOne["isReturned"]}`} */}
                                        <select value={`${eachOne["isReturned"]}`} style={{ width: '40%' }} disabled={eachOne["isReturned"]} onChange={() => getUpdateReturnedStatus(eachOne?._id)}>
                                            <option value={'false'}>No</option>
                                            <option value={'true'}>Yes</option>
                                        </select>
                                    </p>
                                    {eachOne["isReturned"] && <>
                                        <p className=" ">
                                            <span style={{ fontWeight: 600 }}>Received By</span>: {`${eachOne["ReceivedBy"]}`}
                                        </p>
                                        <p className=" ">
                                            <span style={{ fontWeight: 600 }}>Received Date</span>: {`${moment(new Date(eachOne["ReceivedOn"])).format("DD-MM-YYYY") == 'Invalid date' ? eachOne["ReceivedOn"] : moment(new Date(eachOne["ReceivedOn"])).format("DD-MM-YYYY")}`}
                                        </p>
                                    </>}
                                </div>
                            </li>
                        );
                    }) : <li style={{ listStyleType: 'none' }} className="text-center mb-0">No Data</li>}
                </ol>
            </>
        );
    };
    const getDisabledStatus = () => {
        const newAssetsKeys = Object.keys(AddNewAssets)
        let requiredAssetsFormat = []
        newAssetsKeys && newAssetsKeys?.length > 0 && newAssetsKeys?.forEach((key) => {
            requiredAssetsFormat = [...requiredAssetsFormat, ...AddNewAssets[key]]
        })
        const ValidAssetsData = requiredAssetsFormat?.filter((asset) => asset && asset?.serial_no !== '' && asset?.serial_no !== null)
        // console.log(ValidAssetsData, "ValidAssetsData ValidAssetsData")
        setIsSaveBtnActive(ValidAssetsData?.length > 0 ? true : false)
    }

    const getUpdateReturnedStatus = async (id) => {
        const payload = { "userId": selectedEmployee?._id, "assetId": id }
        try {
            const response = await fetchData({
                url: URLS.returnAsset,
                method: "put",
                body: payload,
            });
            modelHandle();
            getEmployeeList();
        } catch (error) {
            toast.error("Failed to save assets. Please try again.");
        }

    }
    console.log(selectedEmployee, "selectedEmployee selectedEmployee")

    return (
        <div>
            <Modal size="lg" show={isStatusModel} onHide={modelHandle} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "1.4rem" }}>
                        {selectedEmployee ? selectedEmployee?.employeeName : ""} Assets
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="assets-modal-body-cls">
                    <div className="asset-list-container">
                        <ul className="asset-list">
                            {assets.map((eachAsset, i) => (
                                <li key={eachAsset.ItemName + i} className="asset-item">
                                    <div className="d-flex justify-content-between">
                                        <label className="asset-label">
                                            <input
                                                type="checkbox"
                                                className="asset-checkbox"
                                                checked={!!checkedAssets[eachAsset.ItemName]}
                                                onChange={() => handleCheckboxChange(eachAsset.ItemName)}
                                            />
                                            <span className="custom-checkbox"></span>
                                            {eachAsset.ItemName}
                                        </label>
                                        {!!checkedAssets[eachAsset.ItemName] && (
                                            <IconButton
                                                className="add-asset-btn-cls"
                                                onClick={() => handleAddSerialInput(eachAsset.ItemName)}
                                            >
                                                <AddCircleRoundedIcon sx={{ color: "#54C154" }} />
                                            </IconButton>
                                        )}
                                    </div>

                                    {checkedAssets[eachAsset.ItemName] && (
                                        <div className="asset-details">
                                            <p style={{ fontWeight: 600, fontSize: "0.9rem" }}>
                                                Existing Details:
                                            </p>
                                            {getExistingDetails(eachAsset?.ItemName?.toLowerCase())}
                                            {AddNewAssets[eachAsset.ItemName] && AddNewAssets[eachAsset.ItemName]?.length > 0 && <div style={{ backgroundColor: '#f1f3fb', padding: '6px', borderRadius: '3px' }}>
                                                <p style={{ fontWeight: 600, fontSize: '0.9rem' }}>New Serial Number:</p>
                                                {AddNewAssets[eachAsset.ItemName]?.map((serial, index) => (
                                                    <div key={index} className="new-serial-input my-2">
                                                        {/* {`${JSON.stringify(serial)}`} */}
                                                        {/* <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        value={serial?.['serial_no'] || ''}
                                                        placeholder="Enter serial number"
                                                        onChange={(e) =>
                                                            handleSerialNumberChange(
                                                                eachAsset.ItemName,
                                                                index,
                                                                e.target.value
                                                            )
                                                        }
                                                        fullWidth
                                                    /> */}
                                                        {/* <label> */}
                                                        <input
                                                            type="text"
                                                            value={serial?.['serial_no'] || ''}
                                                            placeholder="Enter serial number"
                                                            onChange={(e) =>
                                                                handleSerialNumberChange(
                                                                    eachAsset.ItemName,
                                                                    index,
                                                                    e.target.value
                                                                )
                                                            }
                                                            className="serial-input"
                                                            size={'small'}
                                                        />
                                                        {/* </label> */}
                                                    </div>
                                                ))}
                                            </div>}
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveAssets}
                        // disabled={!Object.keys(AddNewAssets).length}
                        disabled={!isSaveBtnActive}

                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ViewAssetsModal;
