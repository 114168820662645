import React, { useEffect, useState } from "react";
import useAxios from "../../helpers/Hooks/useAxios";
import { URLS } from "../../helpers/URLS";
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Button, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveAsset from "./RemoveAsset";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import './AssetManagement.scss';


const AssetManagement = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedAssetDetails, setSelectedAssetDetails] = useState({ id: '', itemName: '', value: '' })
    const [assets, setAssets] = useState([]);
    const [selectedAssetToDelete, setSelectedAssetToDelete] = useState(null)
    const [newAsset, setNewAsset] = useState([{ id: 0, ItemName: '' }])
    const [error, setError] = useState(false)

    const { fetchData } = useAxios();

    const tableRef = React.createRef();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        tableRef.current.scrollTop = 0;
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        assetsList();
    }, []);
    const assetsList = async () => {
        const response = await fetchData({
            url: URLS.getAssetsList,
            method: "get",
        });
        setAssets(response?.Items || []);
    };
    const handleChange = (e) => {
        setSelectedAssetDetails(prev => ({ ...prev, itemName: e.target.name, value: e.target.value }))
    }
    const handleEdit = (asset) => {
        // console.log(asset, "asset asset")
        setSelectedAssetDetails({ id: asset._id, itemName: asset.ItemName, value: asset.ItemName })

    }
    const handleSaveAsset = async (e) => {
        e.preventDefault()
        // console.log(e, "eeeeeeeeeeeeeeeeee", selectedAssetDetails, "selectedAssetDetails")
        if (selectedAssetDetails?.value !== selectedAssetDetails?.itemName) {
            const payload = { "assetId": selectedAssetDetails?.id, "revisedName": selectedAssetDetails?.value }
            const response = await fetchData({
                url: URLS.assetModify,
                method: "put",
                body: payload,

            });
            // console.log(response, "response response")
            // if()
            assetsList();
            setSelectedAssetDetails({ id: '', itemName: '', value: '' })


        } else {
            setSelectedAssetDetails({ id: '', itemName: '', value: '' })
        }
    }
    console.log(selectedAssetToDelete, 'selectedAssetToDelete')

    const handleDeleteAsset = async () => {
        if (selectedAssetToDelete) {
            const payload = { "assetId": selectedAssetToDelete?._id }
            console.log(payload, "payload payload", selectedAssetToDelete, 'selectedAssetToDelete')
            const response = await fetchData({
                url: URLS.removeAsset,
                method: "put",
                body: payload,

            });
            await assetsList();
            await handleClose();
        }
    }
    const handleClose = () => {
        setSelectedAssetToDelete(null)
    }
    const handleAddAsset = (id) => {
        setNewAsset((prevState) => ([...prevState, { id: prevState?.length, ItemName: '' }]))
    }
    const handleRemoveAsset = (id) => {
        console.log(id, "id id")
        const filteredNewAssets = newAsset?.filter((asset) => asset?.id !== id)
        setNewAsset(filteredNewAssets)
    }
    const onChangeNewAssetValue = (id, value) => {
        const updatedNewAssets = newAsset?.map((asset) => asset?.id === id ? ({ ...asset, ItemName: value }) : asset)
        setNewAsset(updatedNewAssets)

    }
    const handleAddNewAssets = async () => {
        const filteredNewAssets = newAsset?.filter((asset) => asset?.ItemName === "")
        if (filteredNewAssets?.length > 0) {
            setError(true)
        } else {
            const updatedNewAssets = newAsset?.map((asset) => ({ ItemName: asset?.ItemName }))
            const payload = { "AssetsList": updatedNewAssets }
            const response = await fetchData({
                url: URLS.createAsset,
                method: "post",
                body: payload,

            });
            assetsList();
            setNewAsset([{ id: 0, ItemName: '' }])
            setError(false)

        }

    }
    // console.log(newAsset, "newAsset newAsset")
    return (
        <div className="row mb-1 pb-1">
            <div className="col-sm-12  mb-2">
                <div className="d-flex flex-column flex-lg-row">
                    <h4 className="title me-lg-5">Asset Management</h4>
                </div>
            </div>
            <div className="col-sm-12 col-lg-8">
                <div className="card d-flex flex-column  pb-1 my-2 my-lg-0" >
                    <TableContainer sx={{ maxHeight: 480, maxWidth: '100%' }} className='table-container-cls assets-table-cls' ref={tableRef}>
                        <Table stickyHeader aria-label="sticky table" className='table-cls' >
                            <TableHead>
                                <TableRow className='py-1'>
                                    <TableCell className="">S.No</TableCell>
                                    <TableCell className="">Asset Name</TableCell>
                                    <TableCell className="">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {assets.map((eachAsset, i) => (
                                    <TableRow key={eachAsset.ItemName + i + 'assets'}>
                                        <TableCell >{i + 1}</TableCell>
                                        <TableCell >
                                            {eachAsset.ItemName === selectedAssetDetails?.itemName ?
                                                <form onSubmit={handleSaveAsset} onBlur={handleSaveAsset}>
                                                    <input
                                                        type="text"
                                                        name={eachAsset.ItemName}
                                                        size="small"
                                                        // fullWidth
                                                        value={selectedAssetDetails?.value || eachAsset.ItemName}
                                                        className="p-1"
                                                        style={{ borderRadius: '5px' }}
                                                        onChange={handleChange}


                                                    />
                                                </form>
                                                : eachAsset.ItemName}
                                        </TableCell>
                                        <TableCell >
                                            <Tooltip title='Edit asset'>
                                                <IconButton
                                                    sx={{ backgroundColor: 'transparent', color: '#F29F05' }}
                                                    aria-label="edit"
                                                    onClick={() => handleEdit(eachAsset)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title='Delete asset'>
                                                <IconButton
                                                    sx={{ backgroundColor: 'transparent', color: '#ea4335' }}
                                                    aria-label="delete"
                                                    onClick={() => setSelectedAssetToDelete(eachAsset)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
                        component="div"
                        count={assets.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className='table-pagination-container'
                    />
                </div>
            </div>
            <div className="col-sm-12 col-lg-4">
                <div className="card d-flex flex-column  p-2 add-new-assets-card-cls" >
                    {/* <div className="d-flex justify-content-between align-items-center px-1 px-lg-3"> */}
                    <h5 className="text-center my-2" style={{ fontWeight: 600 }}>Add New Asset</h5>
                    {/* <Tooltip title='Add New'><IconButton
                            className="add-asset-btn-cls ms-3"
                            onClick={() => handleAddAsset()}
                        // sx={{ visibility: 'hidden' }}
                        >
                            <AddCircleRoundedIcon sx={{ color: "#54C154" }} />
                        </IconButton>
                        </Tooltip> */}
                    {/* </div> */}
                    <form className="d-flex flex-column align-items-center py-3">
                        {newAsset && newAsset?.map((eachAsset, i) => <div key={`${eachAsset?.id}-${i}`} style={{ width: '100%' }} className="d-flex align-items-center my-1 ps-3">
                            <TextField
                                type="text"
                                id="outlined-basic"
                                label="Asset Name"
                                variant="outlined"
                                value={eachAsset?.ItemName}
                                onChange={(e) => {
                                    onChangeNewAssetValue(eachAsset?.id, e.target.value)
                                    // setNewAsset(e.target.value);
                                }}
                                name={eachAsset?.ItemName}
                                size="small"
                                fullWidth
                                className="bg-white"
                                // autoComplete='off'

                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: (!eachAsset?.ItemName && error) ? "#ea4335" : "",
                                            padding: '10px'
                                        },
                                        "&:hover fieldset": {
                                            borderColor: (!eachAsset?.ItemName && error) ? "#ea4335" : "#1976d2",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: (!eachAsset?.ItemName && error) ? "#ea4335" : "#1976d2",
                                            backgroundColor: 'transparent'
                                        },
                                        '& input': {
                                            backgroundColor: 'transparent',
                                            '&:autofill': {
                                                backgroundColor: 'transparent',
                                                WebkitBoxShadow: '0 0 0 1000px white inset',
                                            },
                                        },
                                    },
                                    width: "85%",
                                }}
                            />

                            {eachAsset?.id !== newAsset[0]?.id && <Tooltip title='Remove'><IconButton
                                className="add-asset-btn-cls ms-3"
                                sx={{ backgroundColor: 'transparent', color: '#ea4335', }}
                                aria-label="delete"
                                onClick={() => handleRemoveAsset(eachAsset?.id)}
                            >
                                <HighlightOffIcon />

                            </IconButton></Tooltip>}
                            {eachAsset?.id === newAsset[0]?.id && <Tooltip title='Add new'><IconButton
                                className="add-asset-btn-cls ms-3"
                                onClick={() => handleAddAsset(eachAsset?.id)}
                            // sx={{ visibility: 'hidden' }}
                            >
                                <AddCircleRoundedIcon sx={{ color: "#54C154" }} />
                            </IconButton></Tooltip>}
                        </div>)}
                        <Button size="small" variant="contained" className="mt-4" sx={{ width: '100px' }} onClick={handleAddNewAssets}>Add</Button>
                    </form>
                </div>
            </div>
            {selectedAssetToDelete && <RemoveAsset asset={selectedAssetToDelete} handleDeleteAsset={handleDeleteAsset} handleClose={handleClose} />}
        </div >
    )
}

export default AssetManagement